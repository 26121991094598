:local(.container) {
    background: rgba(255, 255, 255, 1);
    color: #3e3e3e;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 20px;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    overflow: auto;

    b {
        color: #000;
    }
}

:local(.content) {

}

:local(.header) {
    text-transform: uppercase;
    position: relative;
    margin-top: 5%;
    font-weight: 600;
    margin-bottom: 5%;
    color: black;
    font-size: 34px;

    > span {
        font-weight: normal;
    }
}

:local(.description) {
    font-size: 16px;
    text-align: left;
    color: black;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
}

:local(.buttons) {
    :local(.live) {
        margin-top: 30px;
        margin-bottom: 15px;

        :local(.line) {
            background: #00c300;
            color: #fff;
            font-size: 24px;
            height: 40px;
            border-radius: 10px;
            width: 80%;
            cursor: pointer;
            transition: 200ms 0s all;
            transform: translate(0px, 0px);
            margin: auto;
            margin-bottom: 15px;
            text-align: center;
            user-select: none;
            line-height: 40px;
            font-weight: 600;
            display: block;
            text-decoration: none;
        }

        :local(.webchat) {
            background: #414141;
            color: #fff;
            font-size: 24px;
            height: 40px;
            border-radius: 10px;
            width: 80%;
            cursor: pointer;
            transition: 200ms 0s all;
            transform: translate(0px, 0px);
            margin: auto;
            text-align: center;
            user-select: none;
            line-height: 40px;
            font-weight: 600;
            display: block;
            text-decoration: none;
        }
    }

    :local(.standard) {
        margin-top: 15px;

        :local(.wechat) {
            background: #00df65;
            color: #fff;
            font-size: 24px;
            height: 40px;
            border-radius: 10px;
            width: 80%;
            cursor: pointer;
            transition: 200ms 0s all;
            transform: translate(0px, 0px);
            margin: auto;
            margin-bottom: 15px;
            text-align: center;
            user-select: none;
            line-height: 40px;
            font-weight: 600;
            display: block;
            text-decoration: none;
        }

        :local(.phone) {
            background: #fff;
            color: #000;
            font-size: 24px;
            font-weight: bold;
            height: 40px;
            border-radius: 10px;
            width: 80%;
            cursor: pointer;
            transition: 200ms 0s all;
            transform: translate(0px, 0px);
            margin: auto;
            text-align: center;
            user-select: none;
            display: block;
            text-decoration: none;
        }
    }
}