:local(.container) {
    background: rgba(0, 0, 0, 0.6);
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 20px;

    input {
    	font-size: 20px;
    	border: 1px solid #777;
    	padding: 10px;
    	border-radius: 10px;
    	width: calc(100% - 80px);
    	outline: none;
    }

    button {
        font-size: 14px;
        color: #fff;
        border: none;
        border-radius: 10px;
        background: rgb(0, 132, 255);
        float: right;
        width: 70px;
        height: 45px;
        outline: none;
        cursor: pointer;
    }
}

:local(.box) {
    background: #fff;
    font-size: 16px;
    position: absolute;
    left: 20px;
    top: 20%;
    right: 20px;
    padding: 20px;
    padding-top: 30px;
    border-radius: 10px;
    box-shadow: 0 0 13px #333;
}

:local(.form) {
    margin: 30px 0px 20px 0px;
}