@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');

html,
body {
    margin: 0;
    overflow: hidden;
    background: #efefef;
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    font-family: helvetica;
}

#root {
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
}

:local(.mobileContainer) {
    background: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
}

:local(.desktopContainer) {
    background: #fff;
    position: absolute;
    left: 40px;
    top: 40px;
    right: 40px;
    bottom: 40px;
    max-width: 500px;
    max-height: 1000px;
    margin: auto;
    box-shadow: 0px 0px 10px #999;
}

:local(.header) {
    background:  red;
}
