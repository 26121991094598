:local(.container) {
    background: rgba(255, 255, 255, 1);
    color: #3e3e3e;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 20px;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    z-index: 1;

    b {
        color: #ef3389;
    }

    a {
        text-decoration: none;
        color: #ff0000;
    }
}

:local(.content) {
    position: absolute;
    width: 350px;
    height: 330px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

:local(.header) {
    position: relative;
    margin-top: 5%;
    font-weight: bold;
    margin-bottom: 5%;

    > span {
        font-weight: normal;
    }
}

:local(.estimation) {
    font-size: 16px;
}

:local(.optin) {
    background: #ef3389;
    color: #fff;
    font-size: 24px;
    padding: 20px;
    border-radius: 10px;
    width: 70px;
    box-shadow: 0px 0px 15px #de7bbd;
    cursor: pointer;
    transition: 200ms 0s all;
    transform: translate(0px, 0px);
    margin: auto;
    text-align: center;
    user-select: none;

    &:active {
        transform: translate(1px, 1px);
        box-shadow: 0px 0px 10px #de7bbd;
    }
}