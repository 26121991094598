
:local(.notice) {
    color: grey;
    font-size: 10px;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}

:local(.conversationOver) {
    color: grey;
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
}

:local(.messagesClickContainer) {
    font-family: helvetica;
    padding: 0px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 66px;
    overflow: hidden;
}

:local(.messages) {
    font-family: helvetica;
    padding: 20px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
}

:local(.message) {
    overflow: hidden;
    margin-bottom: 10px;
}

:local(.messageFromReceiver) {
    font-size: 14px;
    margin-bottom: 2px;
    margin-left: 12px;

    span {
        color: #333;
        margin-left: 5px;
        font-size: 10px;
    }
}

:local(.messageFromSender) {
    font-size: 14px;
    margin-bottom: 2px;
    margin-right: 12px;
    text-align: right;

    span {
        color: #333;
        margin-right: 5px;
        font-size: 10px;
    }
}

:local(.messageSender) {
    background: rgb(0, 132, 255);
    color: #fff;
    float: right;
    border-radius: 18px;
    padding:  12px 14px;

    a {
        color: #fff;
    }
}

:local(.messageReceiver) {
    background: rgb(241, 240, 240);
    color: #000;
    float: left;
    border-radius: 18px;
    padding:  12px 14px;

    a {
        color: #000;
    }
}


@-webkit-keyframes mercuryTypingAnimation{
    0%{
      -webkit-transform:translateY(0px)
    }
    28%{
      -webkit-transform:translateY(-5px)
    }
    44%{
      -webkit-transform:translateY(0px)
    }
}

:local(.isTyping) {
    background: rgb(241, 240, 240);
    color: #000;
    float: left;
    border-radius: 14px;
    padding:  5px 14px;
}

:local(.tiblock) {
    align-items: center;
    display: flex;
    height: 17px;
}

:local(.ticontainer) :local(.tidot) {
    background-color: #90949c;
}

:local(.tidot) {
    -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
    border-radius: 2px;
    display: inline-block;
    height: 4px;
    margin-right: 2px;
    width: 4px;
}

:local(.tidot):nth-child(1){
    -webkit-animation-delay:200ms;
}
:local(.tidot):nth-child(2){
    -webkit-animation-delay:300ms;
}
:local(.tidot):nth-child(3){
    -webkit-animation-delay:400ms;
}



:local(.messageBox) {
    position: absolute;
    height: 66px;
    bottom: 0;
    right: 0;
    left: 0;
    border-top: 1px solid #efefef;

    textarea {
        border-radius: 10px;
        font-size: 16px;
        margin: 7px;
        padding: 7px;
        border: none;
        outline: none;
        width: calc(100% - 90px);
        background: #efefef;
    }

    button {
        font-size: 14px;
        color: #fff;
        border: none;
        border-radius: 10px;
        background: rgb(0, 132, 255);
        position: absolute;
        right: 6px;
        top: 8px;
        width: 70px;
        height: 50px;
        outline: none;
        cursor: pointer;
    }
}