:local(.container) {
    background: rgba(0, 0, 0, 0.6);
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 20px;
}

:local(.box) {
    background: #fff;
    font-size: 16px;
    position: absolute;
    left: 20px;
    top: 20%;
    right: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 13px #333;
    text-align: center;
    color: #777;

    b {
        color: rgb(0, 132, 255);
    }
}


:local(.heading) {
    color: rgb(0, 132, 255);
    font-size: 24px;
    margin-bottom: 10px;
}

:local(.position) {

    color: rgb(0, 132, 255);
    font-weight: bold;
    font-size: 52px;
    margin-top: 15px;
    margin-bottom: 15px;
}

